<template>
    <div class="page-checkout-step-payment-method">
        <template v-if="mode ==='fitting'">
            <ul class="page-checkout-step-payment-method__description">
                <li class="page-checkout-step-payment-method__description-item">
                    Зачем вам моя карта? 🤔
                </li>
                <li class="page-checkout-step-payment-method__description-item">
                    Программа домашней примерки абсолютно бесплатна!<br />
                    Мы запрашиваем информацию о вашей кредитной или дебетовой
                    карте в целях безопасности, с вас спишется всего 10 ₽, которые
                    вернутся на вашу карту после возвращения очков.
                </li>
            </ul>
        </template>
        <template v-else-if="isPayedByGiftcard">
            <ul class="page-checkout-step-payment-method__description">
                <li class="page-checkout-step-payment-method__description-item">
                    Заказ не требует оплаты ✨
                </li>
                <li class="page-checkout-step-payment-method__description-item">
                    Вся сумма заказа будет списана с вашего подарочного сертификата или по промокоду.
                </li>
            </ul>
        </template>
        <template v-else>
            <div class="page-checkout-step-payment-method__options"
                v-if="availableMethods.length > 0"
                v-bind:class="{ _invalid: $v.$invalid && $v.$dirty }"
                >
                <div class="page-checkout-step-payment-method__options-item"
                    v-for="(item, index) in availableMethods"
                    v-bind:key="index"
                    v-on:click="setMethod(item)"
                    >
                    <page-checkout-option
                        v-bind:is-invalid="$v.$invalid && $v.$dirty"
                        v-bind:item="item"
                        v-bind:is-active="checkoutData.paymentMethod && item.code === checkoutData.paymentMethod.code"
                    />
                </div>
            </div>
            <div class="page-checkout-step-payment-method__error"
                v-if="$v.$dirty && availableMethods.length === 0"
                >
                Не найден подходящий способ оплаты!<br />
                Пожалуйста, свяжитесь с нами для решения этой проблемы.
            </div>
            <div class="page-checkout-step-payment-method__error"
                v-else-if="$v.$invalid && $v.$dirty"
                >
                Выберите один из вариантов
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'page-checkout-step-payment-method',
    inject: [ 'pageCheckoutEventBus', 'mode', 'cartModuleName' ],
    validations() {
        return {
            checkoutData: {
                isValid: value => !!value.paymentMethod,
            },
        };
    },
    computed: {
        ...mapState('checkout', [ 'isPaymentDirty' ]),
        ...mapState('paymentMethods', [ 'getPaymentMethodsResult' ]),
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        isSelfPickup() {
            return this.$store.getters['checkout/isSelfPickup'];
        },
        hasAttachedLensLine() {
            if (this.mode === 'fitting') {
                return false;
            }
            return !this.$store.state.cart.getCartResult || this.$store.state.cart.getCartResult?.lines.some(x => !!x.product.lineChildId);
        },
        availableMethods() {
            const titles = {
                cash: 'Оплата в магазине',
                creditcard: 'Оплата на сайте',
                'gift-card': 'Подарочный сертификат',
            };
            const descriptions = {
                // cash: 'Наличными или картой. Заказы без оплаты откладываем на 4 дня',
                cash: 'Наличными или картой',
                creditcard: `Любыми российскими картами и международными картами МИР`,
                'gift-card': 'Подарочный сертификат',
            };
            const result = this.getPaymentMethodsResult.map(x => ({
                ...x,
                optionTitle: titles[x.code],
                optionDescription: descriptions[x.code],
            }));
            if (this.isPayedByGiftcard) {
                return result.filter(x => x.code === 'gift-card');
            }
            if (!this.isSelfPickup || this.hasAttachedLensLine) {
                return result.filter(x => x.code === 'creditcard');
            }
            return result.filter(x => x.code !== 'gift-card');
        },
        cart() {
            return this.$store.state[this.cartModuleName].getCartResult;
        },
        vouchers() {
            return this.$store.getters['cart/vouchers'];
        },
        isPayedByGiftcard() {
            return this.cartModuleName !== 'fitting' && this.cart?.total === 0 && this.vouchers.length > 0;
        },
    },
    methods: {
        setMethod(newMethod) {
            this.$store.commit('checkout/updateData', {
                paymentMethod: newMethod,
            });
        },
    },
    beforeMount() {
        if (this.mode === 'fitting' && this.getPaymentMethodsResult) {
            this.$store.commit('checkout/updateData', {
                paymentMethod: this.getPaymentMethodsResult.find(x => x.code === 'creditcard'),
            });
        }
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$emit('payment-validation', { method: true });
    },
    watch: {
        isPayedByGiftcard(newVal) {
            if (newVal) {
                this.$store.commit('checkout/updateData', {
                    paymentMethod: this.getPaymentMethodsResult.find(x => x.code === 'gift-card'),
                });
            } else {
                this.$store.commit('checkout/updateData', {
                    paymentMethod: null,
                });
            }
        },
        availableMethods: {
            immediate: true,
            handler(newVal) {
                if (newVal.length === 1 &&
                    (!this.checkoutData.paymentMethod || this.checkoutData.paymentMethod.code !== newVal[0].code)) {
                    this.$store.commit('checkout/updateData', {
                        paymentMethod: newVal[0],
                    });
                    return;
                }
                if (this.checkoutData.paymentMethod && !newVal.some(x => x.code === this.checkoutData.paymentMethod.code)) {
                    this.$store.commit('checkout/updateData', {
                        paymentMethod: null,
                    });
                }
            },
        },
        '$v.$invalid': {
            immediate: true,
            handler(newVal) {
                this.pageCheckoutEventBus.$emit('payment-validation', { method: !newVal });
            },
        },
        isPaymentDirty: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.$v.$touch();
                } else {
                    this.$v.$reset();
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-payment-method {
    &__description {
        display: block;
        padding: 0;
        margin: 0;

        list-style: none;
        &-item {
            position: relative;

            display: block;
            padding-left: 17px;
            &:before {
                content: '—';

                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }
            & ~ & {
                margin-top: 8px;
            }
        }
    }
    &__options {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
        margin-top: 4px;
        margin-bottom: 20px;
        border-top: 1px solid @color-gray-darkest;
        border-left: 1px solid @color-gray-darkest;
        &._invalid {
            border-color: @color-accent-warm;
        }
    }
    &__error {
        margin-top: 8px;

        color: @color-accent-warm;
    }
}
</style>
